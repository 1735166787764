@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply text-davys;
  @apply bg-cultured;
}

.link {
  @apply text-pink;
}

.link:hover,
.link:focus-visible {
  @apply underline;
}

.link-blend:hover,
.link-blend:focus-visible {
  @apply text-pink;
}

.link-keyboard:focus-visible {
  @apply text-pink;
}

@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

.bounce-in {
  animation-duration: 0.75s;
  animation-name: bounceIn;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 4s linear infinite;
}
